import React, { useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Body from './components/Body';
import Footer2 from './components/Footer2';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TagManager from 'react-gtm-module';
import { initFacebookPixel, trackEvent, trackPageView } from './utils/facebook-pixel';
import Unificado from './components/Unificado';

const tagManagerArgs = {
  gtmId: 'GTM-WDZSJQ8Z'
};

TagManager.initialize(tagManagerArgs);

function App() {

  const handleWhatsAppClick = () => {

    TagManager.dataLayer({
      dataLayer: {
        event: 'button_click_1',
        buttonName: 'Btn1',
      },
    });
    trackEvent("button_click_1")
    window.open('https://wa.me/5583981557961?text=Ol%C3%A1%2C%20estou%20interessado%20em%20saber%20mais%20sobre%20os%20servi%C3%A7os%20da%20sua%20empresa.%20Como%20posso%20obter%20mais%20informa%C3%A7%C3%B5es%3F', '_blank');
  };

  const handlePageView = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'acutis_site_view'
      },
    });
    trackPageView();
  };

  useEffect(() => {
    initFacebookPixel();
    handlePageView()
  }, [])

  return (
    <div className="App">
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400..800&display=swap" rel="stylesheet" />
      <Header />
      <Body />
      {/* <Unificado /> */}
      <Footer2 />
      <button className="whatsapp-button" onClick={handleWhatsAppClick}>
        <WhatsAppIcon style={{ fontSize: 40 }} />
      </button>
    </div>
  );
}

export default App;