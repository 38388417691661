import React, {  } from 'react';
import './Body5.css';
import caio from './images/Caio.webp';
import caue from './images/Caue.webp';
import fernando from './images/Fernando.webp';
import { Button } from '@mui/material';
import imagem1 from './images/p1-1.webp'
import imagem2 from './images/p1-2.webp'
import imagem3 from './images/p1-3.webp'
import imagem4 from './images/p1-4.webp'
import imagem5 from './images/p2-1.webp'
import imagem6 from './images/p2-2.webp'
import imagem7 from './images/p3-1.webp'
import imagem8 from './images/p3-2.webp'
import imagem9 from './images/p3-3.webp'
import imagem10 from './images/p4-1.webp'
import imagem11 from './images/p4-2.webp'
import Slider from './Slider';
import TagManager from 'react-gtm-module';
import { trackEvent } from '../utils/facebook-pixel';

const Body5 = () => {

    const handleButtonClick = () => {

        TagManager.dataLayer({
            dataLayer: {
                event: 'button_click_4',
                buttonName: 'Btn4',
            },
        });

        trackEvent("button_click_4")
        window.open('https://wa.me/5583981557961?text=Ol%C3%A1%2C%20gostaria%20de%20conversar%20sobre%20uma%20parceria%20para%20desenvolvimento%20de%20solu%C3%A7%C3%B5es%20digitais%3F', '_blank');
    };

    const handleButtonClick2 = () => {

        TagManager.dataLayer({
            dataLayer: {
                event: 'button_click_6',
                buttonName: 'Btn6',
            },
        });
        window.open('https://wa.me/5583981557961?text=Ol%C3%A1%2C%20gostaria%20de%20conversar%20sobre%20uma%20parceria%20para%20desenvolvimento%20de%20solu%C3%A7%C3%B5es%20digitais%3F', '_blank');
    };

    const images = [
        imagem1, imagem2, imagem3, imagem4, imagem5,
        imagem6, imagem7, imagem8, imagem9, imagem10, imagem11
    ];

    const projects = [
        { id: 1, title: "MemorizaMed", description: "Plataforma de prática de estudos de medicina focada no método de repetição espaçada.", images: [imagem1, imagem2, imagem3, imagem4] },
        { id: 2, title: "Painel Simplificado", description: "Plataforma de gerenciamento simplificado de negócio, permitindo a visualização rápida de indicadores.", images: [imagem5, imagem6] },
        { id: 3, title: "Arbitragem de Criptomoedas", description: "Plataforma com sistema automatizado de busca de oportunidades em arbitragem de criptomoedas.", images: [imagem7, imagem8, imagem9] },
        { id: 4, title: "Área de Membros", description: "Plataforma completa de área de membros com visualização e gerenciamento de cursos, módulos, aulas, alunos e pagamentos.", images: [imagem10, imagem11] },
    ]

    return (
        <div id="projetos" style={{ marginTop: 150, marginBottom: 50, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <div>
                <div className='projetos'>
                    <h1>Nossos Projetos</h1>
                    <h2>Estes são alguns dos negócios que nosso time tem impactado agregando tecnologia de maneira inteligente.</h2>
                    <Slider images={images} projects={projects} />
                    <Button className="quero-o-meu-projeto" onClick={handleButtonClick} style={{ textTransform: 'none' }} variant="contained">
                        Quero o meu projeto também!
                    </Button>
                </div>
                <div className="comunidade">
                    <h1 style={{ marginBottom: 40 }}>Comunidade Acutis Tecnologia</h1>
                    <div style={{ display: 'flex' }} className='jobs'>
                        <div className="funcao">
                            <div className="imagem-container">
                                <img width={400} src={caio} className='funcao-img' alt='profile-image-caio' />
                                <div className="overlay"></div>
                            </div>
                            <h1>Caio Costa</h1>
                            <h3>CEO | Full-Stack Developer</h3>
                        </div>
                        <div className="funcao">
                            <div className="imagem-container">
                                <img width={400} src={caue} className='funcao-img' alt='profile-image-caue' />
                                <div className="overlay"></div>
                            </div>
                            <h1>Cauê Henrique</h1>
                            <h3>Front-end Developer</h3>
                        </div>
                        <div className="funcao">
                            <div className="imagem-container">
                                <img width={400} src={fernando} className='funcao-img' alt='profile-image-fernando' />
                                <div className="overlay"></div>
                            </div>
                            <h1>Fernando d'Ávila</h1>
                            <h3>Front-end Developer</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ideia-do-papel">
                <h1>Pronto para tirar a sua ideia do papel?</h1>
                <h2>Agende um bate papo com a nossa equipe, estamos prontos para o seu desafio!</h2>
                <Button className="agende-uma-reuniao1" onClick={handleButtonClick2} style={{ textTransform: 'none' }} variant="contained">Agende uma reunião</Button>
            </div>
        </div>
    );
}

export default Body5;
