import React from 'react';
import './Footer2.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Mail } from '@mui/icons-material';

function Footer2() {
    
    return (
        <div style={{ backgroundColor: '#019875', color: 'white' }}>
            <div className="footer2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="footer2-textos">
                    <h1 style={{ fontSize: 24, fontWeight: "bold", textAlign: "center" }}>
                        Acutis Tecnologia
                    </h1>
                </div>

                <div className="footer2-textos">
                    <h1>
                        Contatos
                    </h1>
                    <a href='https://www.instagram.com/acutistecnologia/' target="_blank"><h2 style={{ display: 'flex', alignItems: 'center' }}><InstagramIcon sx={{ marginRight: 1 }} />acutistecnologia</h2></a>
                    <a href='mailto:contato@acutistecnologia.com' target="_blank"><h2 style={{ display: 'flex', alignItems: 'center' }}><Mail sx={{ marginRight: 1 }} /> contato@acutistecnologia.com</h2></a>

                </div>
            </div>
            <div style={{ textAlign: 'center', fontSize: 9, fontWeight: 'normal', paddingBottom: 15 }}>
                <h1 style={{ fontWeight: 'normal' }}>© 2024 Acutis Tecnologia. Todos os direitos reservados.</h1>
            </div>
        </div>
    );
}

export default Footer2;