import React from 'react';
import './Body2.css';
import { Button, Card, CardContent, Typography } from '@mui/material';
import imagem from './images/3.webp';
import TagManager from 'react-gtm-module';
import { trackEvent } from '../utils/facebook-pixel';


function Body2() {

    const handleButtonClick = () => {

        TagManager.dataLayer({
            dataLayer: {
                event: 'button_click_5',
                buttonName: 'Btn5',
            },
        });

        trackEvent("button_click_5")
        window.open('https://wa.me/5583981557961?text=Oi%2C%20estou%20precisando%20de%20uma%20consultoria%20para%20melhorar%20os%20processos%20de%20TI%20da%20minha%20empresa.', '_blank');
    };

    return (
        <div id="servicos" style={{ backgroundColor: '#001f3a', paddingBottom: 80, paddingTop: 50 }}>
            <div className="body2-principal" style={{ display: 'flex' }}>
                <div className='img-blocks' style={{ marginTop: 25, marginLeft: 15 }}>
                    <img src={imagem} width={350} alt='banner'/>
                </div>
                <div className='div-o-que-fazemos' style={{ backgroundColor: '#001f3a', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="o-que-fazemos">
                        <h1>Dúvidas Comuns</h1>
                        <Button className="entre-em-contato" onClick={handleButtonClick} style={{ textTransform: 'none' }} variant="contained">Entre em contato</Button>
                    </div>
                    <div className="caixas1">
                        <div>
                            <div className='caixas2'>
                                <Card sx={{ width: 350 }} className="card2">
                                    <CardContent>
                                        <Typography variant="h6" component="div" style={{ fontWeight: 'bolder' }}>
                                            Qual é o papel de uma software house?
                                        </Typography>
                                        <Typography variant="body2" className="card-descricao2">
                                            Criamos softwares personalizados e soluções automatizadas que ajudam empresas a superar desafios complexos e melhorar sua eficiência. Nosso objetivo é economizar recursos e otimizar processos, permitindo que nossos clientes concentrem seus esforços no crescimento e sucesso do negócio.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                            <div className='mini-container-caixas' style={{ marginTop: 50 }}>
                                <Card sx={{ width: 350, borderBottomColor: '#006598' }} className="card2">
                                    <CardContent>
                                        <Typography variant="h6" component="div" style={{ fontWeight: 'bolder' }}>
                                            Quais empresas podem se beneficiar de nossos serviços?
                                        </Typography>
                                        <Typography variant="body2" className="card-descricao2">
                                            Atendemos empresas que enfrentam dificuldades operacionais, buscam soluções tecnológicas feitas sob medida, desejam automatizar processos e resolver problemas específicos por meio de tecnologia inovadora.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                        <div className='container-caixas' style={{ marginTop: 100 }}>
                            <div>
                                <Card sx={{ width: 350, marginRight: 0, borderBottomColor: '#99ccff' }} className="card2">
                                    <CardContent>
                                        <Typography variant="h6" component="div" style={{ fontWeight: 'bolder' }}>
                                            Quanto tempo leva para criar uma solução personalizada?
                                        </Typography>
                                        <Typography variant="body2" className="card-descricao2">
                                            O prazo depende da complexidade e das particularidades do projeto. Temos um cuidadoso processo de onboarding e após entender as necessidades do cliente, elaboramos um cronograma sob medida, garantindo agilidade e excelência na entrega da solução.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                            <div className='mini-container-caixas' style={{ marginTop: 50 }}>
                                <Card sx={{ width: 350, marginRight: 0, borderBottomColor: '#99ccff' }} className="card2">
                                    <CardContent>
                                        <Typography variant="h6" component="div" style={{ fontWeight: 'bolder' }}>
                                            Há suporte após a entrega do projeto?
                                        </Typography>
                                        <Typography variant="body2" className="card-descricao2">
                                            Sim, oferecemos garantia, suporte e manutenção após a entrega. Para quem busca um acompanhamento contínuo e auxílio para fazer o seu sistema continuar crescendo, disponibilizamos planos personalizados.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='img-blocks' style={{ alignSelf: 'flex-end', marginLeft: 30 }}>
                    <img src={imagem} width={350} alt='background-image'/>
                </div>
            </div>
        </div >
    );
}

export default Body2;
