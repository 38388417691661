import React, { lazy, Suspense } from 'react';
import { Button, Card, CardContent, Typography } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './Header.css';
import image from './images/6.webp'

// Lazy load tracking modules
const TagManager = lazy(() => import('react-gtm-module'));
const { trackEvent } = lazy(() => import('../utils/facebook-pixel'));

function Header() {
    const handleButtonClick = () => {
        // Wrap tracking calls in try-catch and only execute if modules are loaded
        try {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'button_click_2',
                    buttonName: 'Btn2',
                },
            });
            trackEvent("button_click_2");
        } catch (error) {
            console.error('Tracking error:', error);
        }
        window.open("https://wa.me/5583981557961", "_blank");
    };

    return (
        <header style={{ backgroundColor: "#001f3a" }}>
            <nav className="top">
                <div>
                    <img 
                        width={80} 
                        height={80} 
                        src={image} 
                        alt='logo-acutis'
                        loading="eager"
                    />
                </div>
            </nav>
            <div className="texto-principal">
                <h1 style={{ textTransform: 'uppercase' }}>Desenvolvemos softwares únicos que impulsionam seu negócio</h1>
                <div className='button-div'>
                    <Button 
                        className="fale-conosco"
                        onClick={handleButtonClick}
                        style={{ textTransform: 'none' }} 
                        variant="contained"
                    >
                        <WhatsAppIcon
                            sx={{
                                marginRight: 1,
                                fontSize: 30
                            }}
                        />
                        Fale Conosco
                    </Button>
                </div>
            </div>
            <div className="caixas">
                {['Qualidade', 'Inovação', 'Resultado'].map((title, index) => (
                    <Card key={index} sx={{ width: 400 }} className="card1">
                        <CardContent>
                            <Typography variant="h5" component="div" className='porcentagem'>
                                {title}
                            </Typography>
                            <Typography variant="body2" className="card-descricao">
                                {index === 0 && "Excelência técnica com entregas rápidas. Desenvolvemos seu app com as melhores práticas e tecnologias do mercado."}
                                {index === 1 && "Transforme seu investimento em valor real. Construímos soluções eficientes que impulsionam o crescimento do seu negócio."}
                                {index === 2 && "Monetização acelerada do seu projeto. Comece a ter retorno do seu investimento mais rápido com entregas em tempo recorde."}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </header>
    );
}

export default Header;

